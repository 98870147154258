<template>
	<div class="notice_popup evl_item">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
        </div>
		<div class="tit">평가항목 등록</div>
		<div class="item_box">
			<div class="label ivl14">평 가 자</div>
			<div class="detail">
				<SelectComp type="text" v-model="evalItem.evaluatorDivCd" cdId="PRO109"/>
			</div>
			<div class="label">평가항목구분</div>
			<div class="detail">
				<SelectComp type="text" v-model="evalItem.evalItemDivCd" cdId="PRO110"/>
			</div>
			<div class="label ivl15">평 가 항 목</div>
			<div class="detail">
				<SelectComp type="text" v-model="evalItem.evalItemCd" cdId="PRO111"/>
			</div>
			<div class="label">평가항목내용</div>
			<div class="detail">{{evalItem.evalItemCont}}</div>
		</div>
		<div class="member">입력하신 내용으로 등록하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="$emit('close', true)">예</div>
		</div>
	</div>
</template>

<script>
export default {
	props:['param'],
	data(){
		return{
			evalItem : this.param
			// evalItem : {
			// 	evalItemSeq : '',
			// 	evaluatorDivCd : '',
			// 	evalItemDivCd : '',
			// 	evalItemCont : ''
			// },

			//evalItemSeq : this.$route.params.evalItemSeq || ''
		}
	},

	beforeMount(){
		//this.getEvalItem();
	},

	// mounted(){
	// 	this.getEvalItem();
	// },

	methods : {
		getEvalItem(){
			var param = {};
			param.evalItemSeq = this.evalItemSeq;

			this.$.httpPost('/api/main/adm/eval/getEvalItem', {evalItemSeq : this.evalItemSeq})
				.then(res=>{
					this.evalItem = res.data.evalItem || {};
				}).catch(this.$.httpErrorCommon);
		}
	}
}
</script>